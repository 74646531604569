<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />{{ $t('Add') }}
        </a-button>
      </a-row>
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="list"
    >
      <template slot="title" slot-scope="item">
        <div>
          {{ item.title }}
        </div>
      </template>
      <template slot="uid" slot-scope="item">
        <strong>{{ item.uid }}</strong>
      </template>
      <template slot="is_active" slot-scope="item">
        <a-switch
          v-model="item.is_active"
          style="margin-right: 15px"
          @change="checkActive(item, $event)"
        />
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? "Опубликован" : "Не опубликован" }}
        </a-tag>
      </template>
      
      <template slot="operation" slot-scope="item">
        <!-- <a-icon
          class="action-btns"
          type="edit"
          @click="
            $router.push(
              { 
                name: 'post-update',
                params: { id: item.id },
                query: { menu: $route.params.id }
              },
              () => null
            )
          "
        /> -->
        <a-button type="primary" class="edit-btn" @click="editOpendata(item.id)">
          {{ $t("Edit") }}
          <a-icon type="edit" class="action-btns" />
        </a-button>
        <!-- <a-popconfirm
          :cancel-text="$t('No')"
          :ok-text="$t('Yes')"
          :title="$t('APopconfirm')"
          @confirm="removeItem(item)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm> -->
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <!-- lang translit tabs -->
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          Ma'lumotlar to'plami nomi:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            @change="slugCompute($event, langTab, 'menu', formOpendata.title)"
            v-model="formOpendata.title[getKey]"
            :placeholder="$t('Placeholder')"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          Taqdim etuvchi tashkilot:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            @change="slugCompute($event, langTab, 'menu', formOpendata.source)"
            v-model="formOpendata.source[getKey]"
            :placeholder="$t('Placeholder')"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          ID Raqam (kodi):
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="formOpendata.uid"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          {{ $t('Url') }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="formOpendata.link"
          />
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" class="form_title">
          Fayl {{ $t('Url') }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="formOpendata.file_link"
          />
          <p>https://data.egov.uz/apiData/MainData/GetByFile?id=</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          {{ $t("Status") }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-switch v-model="formOpendata.is_active" />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> {{ $t('Close') }} </a-button>
        <a-button type="primary" @click="saveOpendata">{{ $t('Save') }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

export default {
  data() {
    return {
      list: [],
      modalVisible: false,
      langTab: $langPrefix,
      formOpendata: {
        title: { 
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        source: { 
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        uid: "",
        is_active: true,
        link: "",
        file_link: ""
      },
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "title",
          },
        },
        {
          title: "ID Raqam",
          key: "uid",
          width: "20%",
          scopedSlots: { customRender: "uid" },
        },
        {
          title: this.$t("Status"),
          key: "is_active",
          fixed: "right",
          width: "200px",
          scopedSlots: { customRender: "is_active" },
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          fixed: "right",
          // width: "120px",
          scopedSlots: { customRender: "operation" },
        },
      ],
      errors: {
        slug: "",
      },
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async checkActive(item, event) {
      try {
        await this.$api.put(`/admin/open-data/${item.id}`, {
          is_active: event,
        });
      } catch (error) {
        console.error(error);
      }
    },
    
    async fetchData() {
      // this.loading = true;
      try {
        const data = await this.$store.dispatch("opendata/fetch");
        data && this.$set(this, "list", data);
        // this.loading = false;
      } catch (err) {
        console.log(err);
      }
      // this.loading = false;
    },
    async saveOpendata() {
      if(this.formOpendata.id){
        await this.$api.put(`/admin/open-data/${this.formOpendata.id}`, this.formOpendata)
      }else{
        await this.$api.post("/admin/open-data/create", this.formOpendata)
      }
      this.modalVisible = false
      this.$router.go();
    },
    openCreateModal() {
      this.modalVisible = true
    },
    async editOpendata(value) {
      const { data } = await this.$api.get(`/admin/open-data/${value}`)
      this.formOpendata.id = data && data.data && data.data.id
      this.formOpendata.title = data && data.data && data.data.title
      this.formOpendata.source = data && data.data && data.data.source
      this.formOpendata.uid = data && data.data && data.data.uid
      this.formOpendata.link = data && data.data && data.data.link
      this.formOpendata.file_link = data && data.data && data.data.file_link
      this.formOpendata.is_active = data && data.data && data.data.is_active
      
      console.log(data.data)
      this.modalVisible = true
    },
  },
};
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
